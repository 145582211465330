.App {
  text-align: center;
  background-color: #0c0c10;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(8px + 1vmin);
  color: #d8d8f0;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  overflow-x: hidden;
  font-family: "Roboto Mono", sans-serif;
}

.App-logo {
  position: absolute;
  width: 200px;
  top: 10px;
  left: 10px;
}

.App-header {
  margin-bottom: 16px;
}

.App-subtitle {
  margin: 0;
  font-size: calc(8px + 0.8vmin);
}

a {
  color: rgb(0, 152, 161);
}

h1 {
  margin-top: 50px;
  margin-bottom: 8px;
  font-size: calc(10px + 3vmin);
}

p {
  margin: 16px 0 8px 0;
  font-size: calc(10px + 1.5vmin);
}
